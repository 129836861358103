<template>
  <transition name="slide-up">
    <div
      v-if="isOpen"
      class="fixed bottom-4 right-4 md:right-8 bg-white shadow-lg w-full md:w-96 max-w-sm rounded-lg overflow-hidden border-t-4 border-grey"
      style="z-index: 9999;"
    >
      <!-- Header with Close and Minimize Icons -->
      <div class="bg-grey text-white flex justify-between items-center py-3 px-4">
        <span class="font-semibold text-lg">Chat with {{ user.fullname }}</span>
        <div class="flex items-center gap-2">
          <v-icon @click="toggleMinimize" class="cursor-pointer text-primary hover:bg-blue-700 rounded-full p-1">mdi-minus</v-icon>
          <v-icon @click="closeChat" class="cursor-pointer text-primary hover:bg-red-600 rounded-full p-1">mdi-close</v-icon>
        </div>
      </div>

      <!-- Chat Body (toggle between chat and minimized state) -->
      <div v-if="!isMinimized" class="p-4 flex flex-col h-72">
        <!-- Chat Messages Placeholder -->
        <div class="flex-1 overflow-y-auto mb-4 space-y-3">
          <div
            v-for="msg in messages"
            :key="msg.id"
            :class="msg.fromMe ? 'flex justify-end' : 'flex justify-start'"
          >
            <div
              :class="[
                'p-3',
                'rounded-xl',
                msg.fromMe ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black'
              ]"
              class="max-w-xs"
            >
              {{ msg.text }}
            </div>
          </div>
        </div>

        <!-- Chat Input -->
        <div class="flex items-center gap-3">
          <v-text-field
            v-model="message"
            placeholder="Type your message..."
            dense
            outlined
            hide-details
            class="flex-1 bg-gray-50 rounded-full"
            @keyup.enter="sendMessage"
          />
          <v-btn @click="sendMessage" class="bg-green-500 hover:bg-green-600 text-white rounded-full px-4 py-2">
            <v-icon class="text-primary" left>mdi-send</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isOpen = ref(false); // Chat window visibility
const isMinimized = ref(false); // Minimize state
const message = ref(""); // Message input model
const messages = ref([
  { id: 1, text: "Hi there!", fromMe: false },
  { id: 2, text: "Hello!", fromMe: true },
]); // Chat messages array (for demonstration)
const user = ref({ fullname: "User" }); // Mocked user data

// Open chat window
const openChat = () => {
  isOpen.value = true;
  isMinimized.value = false;
};

// Close chat window
const closeChat = () => {
  isOpen.value = false;
};

// Minimize chat window
const toggleMinimize = () => {
  isMinimized.value = !isMinimized.value;
};

// Send message function
const sendMessage = () => {
  if (message.value.trim()) {
    messages.value.push({ id: Date.now(), text: message.value, fromMe: true });
    message.value = ""; // Reset input
  }
};
</script>

<style scoped>
/* Animation for the chat window sliding up */
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.3s ease;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(100%);
}
</style>
