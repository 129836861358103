export const fr = {
  "message": {
    "HammayounSaficvEn": "HammayounSaficvFr",
    "heading": {
      "PersonalProfile" : "Profil Personnel",
      "ExperienceAndEducation": "Expérience && Éducation",
      "Skill": "Compétence",
      "Contact": "Contact"
    },
    "terminal": {
      "questions": {
        "myNameAndAGe": "mon_nom, mon_age",
        "from": "de",
        "lived": "vivait",
        "living": "vie",
        "myHobbies": "mes_loisirs",
        "languages": "langues",
        "programmingLanguages": "langages_de_programmation",
        "databaseServer": "serveur_de_base_dedonnées",
        "webTechnologies": "technologies_web",
        "frameworks": "frameworks",
        "otherDevTool": "autre_outil_de_développement"
      },
      "answers": {
        "myNameAndAGe": "'Hammayoun Safi (Hama)'",
        "from": "'Afghanistan'",
        "lived": "['Afghanistan', 'Pakistan', 'Suède', 'Italie']",
        "living": "'Malakoff, France'",
        "myHobbies": "['développement', 'voyage', 'randonnée', 'snowboard', 'natation', 'vélo']",
        "languages": "['Pachto', 'Français', 'Anglais', 'Ourdou', 'Hindi', 'Persan']",
        "programmingLanguages": "['Javascript', 'Ruby', 'Bash']",
        "databaseServer": "['PostgreSQL']",
        "webTechnologies": "['HTML', 'CSS']",
        "frameworks": "['Ruby on Rails', 'VueJS', 'NodeJS(express)', 'Bootstrap']",
        "otherDevTool": "['Git', 'Github', 'Heroku', 'Linux', 'Postman', 'Algolia', 'Auth0/Device', 'Sentry/Rollbar', 'Sendgrid', 'Circleci', 'AWS']"
      },
      "phrase":  {
        "exit": "exit",
        "cv": "Vous pouvez jeter un oeil à mon",
        "terminalHeading":  "Hama99o - développeur web",
        "chosePage1": "Vous pouvez écrire le numéro de page pour changer de page: { experience_et_education: 0, competence: 1, contact: 2 }",
        "chosePage2": "discutons",
        "errorMessage":  [
          "Je sais cela.",
          "Quelque part entre le meilleur et le meilleur.",
          "Je ne peux pas me plaindre. Personne n'écoute de toute façon.",
          "Jusqu'ici tout va bien!",
          "Peut-être qu'un jour tu auras la chance de le découvrir.",
          "Dois-je répondre ?",
          "Pourquoi demandez-vous ? Êtes-vous médecin ?",
          "Je ne sais pas. C'est encore vendredi ?",
          "Comment pensez-vous que je suis ?",
          "Putain de merde, tu peux me voir ?! (Cela ne manque jamais de faire rire.)",
          "Question suivante, s'il vous plaît",
          "Vivre le rêve!",
          "Pas aujourd'hui, Satan!",
          "Je suis toujours en train d'aspirer de l'air.",
          "Fais-moi juste un câlin et n'en parlons plus.",
          "Parce que personne de digne ne m'a encore battu dans un combat de cartes !",
          "C'est parce que je mange de la pizza avec une fourchette.",
          "Deux mots : problèmes de confiance !",
          "Attendez, laissez-moi sortir ma liste.",
          "Ça va  ?",
          "Tu sais quoi ? Je t'aime bien.",
          "Merci, mais ce n'est pas à vendre!",
          "Arrête ça avant que je ne tombe amoureux de toi.",
          "Qui t'a dit de me dire ça ?",
          "Je ne pourrais pas être plus d'accord!"
        ]
      }
    },
    "experienceAndEducation": {
      "location" : {
        "jamLocation": "lieu: France",
        "SscarabeeLocation": "lieu: France",
        "alvkungenLocation": "lieu: Suède",
        "honeySellerLocation": "lieu: Pakistan",
        "konexioLocation": "lieu: France",
        "balderLocation": "lieu: Suède",
        "milatSchoolLocation": "lieu: Pakistan"
      },
      "year": {
        "jamYear": "Depuis déc 2020",
        "scarabeeYear": "2019 - 2020",
        "alvkungenYear": "2016 - 2018",
        "honeySellerYear": "2013 - 2015",
        "konexioYear": "2019 - 2020",
        "balderYear": "2015 - 2019",
        "milatSchoolYear": "2012 - 2015",
        "drZareefYear": "2010-2012"
      },
      "text": {
        "jamText2": "Développeur junior pour",
        "jamText3": "",
        "jamText4": "est un média conversationnel, un robot dans Facebook Messenger, qui a pour objectif d'inspirer les jeunes dans leur quotidien en leur proposant un sujet sur lequel ils peuvent donner leur avis.",
        "jamText5": "",
        "jamText6": "",
        "jamText7": "",
        "jamText8": "Conception d'une système de gestion des clients avec ruby on rails dans Jam Trends, Jam Trends partage des sondages et des études sur 15-25 chaque fois que nous devions ajouter un nouvel abonné dans site Jam Trends, nous avions besoin d'un développeur car le système de gestion des clients n'avait qu'un backend, j'ai donc construit une interface en utilisant ce backend.",
        "jamText9": "Construction d'une API avec rails et Express et Conception d'une interface dans Vue Js pour les messages de référence qui nous aident à trouver une conversation spécifique sur Facebook Messenger du chatbot.",
        "jamText10": "Ajout de colonnes de qualification pour le panel et le rapport qui aident notre collègue à créer un rapport sur un sexe, un âge ou une ville spécifique.",
        "jamText11": "Amélioration de système de marquage pour répondre à une question ouverte",
        "jamText12": "Amélioration de Graphique de rapport",
        "jamText13": "Conception de filtre statique par tag pour répondre aux questions ouvertes",
        "jamText14": "Optimisation de la création de panneaux et de rapports",
        "jamText15": "Isolement de Trend Reports à partir de bases de données de bots",
        "jamText16": "Ajout de l'estimation de la population INSEE (Institut national de la statistique et des études économiques) 2020 pour la création des panneaux et des rapports",
        "jamText17": "Amélioration de liste des sondages et dans messenger_bot gem",
        "jamText18": "Conception et développement Interface de modération pour",
        "scarabeeText1": "Interprète bénévole à l'association",
        "scarabeeText2": "",
        "scarabeeText3": "Aidé les nouveaux réfugiés en situation difficile et ceux qui ne comprennent pas le français. par exemple. obtenir des rendez-vous avec le médecin ou le bureau administratif ou obtenir un avocat. (À temps partiel)",
        "alvkungenText1": "Guide touristique en",
        "alvkungenText2": "Bateau de tourisme : accueil de groupes touristiques, guide et pilotage du bateau. (À temps partiel)",
        "honeySellerText1": "Vendeur en magasin de miel à Al Mashrik .",
        "honeySellerText2": "Vendeur auprès d'un producteur de miel artisanal (distribution internationale) Vente aux commerçants et aux particuliers.",
        "konexioText": "Préparation au Delf B1 (diplôme d'études de langue française) et développement Web chez Konexio Digistart.",
        "balderText": "Appris le suédois et le développement web au lycée Balder",
        "milatSchoolText": "Baccalauréat de l'école Milat / L'Abasyn Web Development Institute",
        "drZareefText": "École secondaire de Dr zareef Memorial school"
      },
      "options": {
        "seeMore": "Voir Plus",
        "seeLess": "Voir Moins",
        "abouRecentJob": "À propos de mon emploi récent",
        "myWork": "Mon travail",
        "myCv": "Mon CV",
        "MyWorkInMultiBot": "Mon travail dans le système de récupération de données multi-bot"

      }
    },
    "skills":  {
      "heading": {
        "programmingSkill": "Compétences en Programmation",
        "myProjects": "Mes Projets",
        "interests": "intérêts"
      },
      "interests": {
        "travel": "Voyager",
        "cycle": "Cycle",
        "music": "Musique",
        "game": "jouer",
        "chess": "Échecs",
        "snowboard": "Snowboard"
      },
      "myProjects": {
        "and": "et"
      },
      "programmingSkill": {
        "programmingLanguages": "Langages des programmations",
        "databaseServer": "Base de données",
        "webTechnologies": "Technologies Web",
        "frameworks": "Frameworks",
        "otherDevTool": "Autres outils de dév",
        "famousLibraries": "Bibliothèques célèbres"
      }
    },
    "contact": {
      "heading": {
        "follow": "Suivre"
      }
    }
  }
}
