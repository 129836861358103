<template>
  <v-app :theme="theme">
    <MainMenu />

    <!-- Main content -->
    <div>
      <router-view></router-view>
      <bkt-pop-up />
    </div>

    <!-- Global Chat Window -->
    <chat-window ref="chatWindow" />

    <Toast />
  </v-app>
</template>

<script setup lang="ts">
import MainMenu from '@/components/layouts/MainMenu.vue';
import Toast from '@/components/Toast/Index.vue';
import BktPopUp from "@/components/popUpComponents/BktPopUp.vue";
import { useUserStore } from '@/stores/user.store';
import { storeToRefs } from 'pinia';

// Import the chat window component
import ChatWindow from "@/components/conversation/ChatWindow.vue";

const { theme } = storeToRefs(useUserStore());

// Function to open the chat window
const openChatWindow = () => {
  const chatWindow = ref(null);
  chatWindow.value?.openChat();
};
</script>

<style>
@import 'vuetify/styles';
@import '@/assets/css/style.css';


::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
	background-color: #5e5454;
}

::-webkit-scrollbar
{
	width: 14px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  background-color: #ad8484;

}
</style>
