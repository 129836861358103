export const en =  {
  "message": {
    "HammayounSaficvEn": "HammayounSaficvEn",
    "heading": {
      "PersonalProfile": "Personal Profile",
      "ExperienceAndEducation": " Experience && Education ",
      "Skill": "Skill",
      "Contact": "Contact"
    },
    "terminal": {
      "questions": {
        "myNameAndAGe": "my_name, my_age",
        "from": "from",
        "lived": "lived",
        "living": "living",
        "myHobbies": "my_hobbies",
        "languages": "languages",
        "programmingLanguages": "programming_languages",
        "databaseServer": "database_server",
        "webTechnologies": "web_technologies",
        "frameworks": "frameworks",
        "otherDevTool": "other_dev_tool"
      },
      "answers": {
        "myNameAndAGe": "'Hammayoun Safi (Hama)'",
        "from": "'Afghanistan'",
        "lived": "['Afghanistan', 'Pakistan', 'Sweden', 'Italy']",
        "living": "'Malakoff, France'",
        "myHobbies": "['development', 'travel', 'hiking', 'snowboarding', 'swimming', 'cycling']",
        "languages": "['Pashto', 'French', 'English', 'Urdu', 'Hindi', 'Persian']",
        "programmingLanguages": "['Javascript', 'Ruby', 'Bash']",
        "databaseServer": "['PostgreSQL']",
        "webTechnologies": "['HTML', 'CSS']",
        "frameworks": "['Ruby on Rails', 'VueJS', 'NodeJS(express)', 'Bootstrap']",
        "otherDevTool": "['Git', 'Github', 'Heroku', 'Linux', 'Postman','Algolia', 'Auth0/Device', 'Sentry/Rollbar', 'Sendgrid', 'Circleci', 'AWS']"
      },
      "phrase":  {
        "exit": "exit",
        "cv": "You can take a look at my",
        "terminalHeading":  "Hama99o - Web developer",
        "chosePage1": "You can write the chosen page number to change a page: { experience_and_education: 0, skill: 1, contact: 2 }",
        "chosePage2": "let's chat",
        "errorMessage":  [
          "I know it.",
          "Somewhere between better and best.",
          "Can't complain. Nobody listens anyway.",
          "So far, so good!",
          "Maybe one day you'll be lucky enough to find out.",
          "Do I have to answer?",
          "Why do you ask? Are you a doctor?",
          "I dunno. Is it Friday yet?",
          "How do you think I am?",
          "Holy sh*t, you can see me?! (This never fails in bringing on the chuckles.)",
          "Next question, please",
          "Living the dream!",
          "Not today, Satan!",
          "I'm still sucking air.",
          "Just hug me and leave it at that.",
          "Because no one worthy has beaten me yet in a card fight!",
          "That’s because I eat pizza with a fork.",
          "Two words: Trust issues!",
          "Wait, let me pull out my list.",
          "Are you ok  ?",
          "You know what? I like you.",
          "Thanks, but it's not for sale!",
          "Stop it before I fall in love with you.",
          "Who told you to tell me that?",
          "I couldn’t agree more!",
          "Are you a spy? Who sent you? (Feeling a little paranoid, huh?)"
        ]
      }
    },
    "experienceAndEducation": {
      "location" : {
        "jamLocation": "location: France",
        "scarabeeLocation": "location: France",
        "alvkungenLocation": "location: Sweden",
        "honeySellerLocation": "location: Afghanistan/Pakistan",
        "konexioLocation": "location: France",
        "balderLocation": "location: Sweden",
        "milatSchoolLocation": "location: Pakistan"
      },
      "year": {
        "jamYear": "Since dec 2020",
        "scarabeeYear": "2019 - 2020",
        "alvkungenYear": "2016 - 2018",
        "honeySellerYear": "2013 - 2015",
        "konexioYear": "2019 - 2020",
        "balderYear": "2015 - 2019",
        "milatSchoolYear": "2012 - 2015",
        "drZareefYear": "2010-2012"
      },
      "text": {
        "jamText2": "Junior developer for",
        "jamText3": "",
        "jamText4": "is a conversational media, a robot in Facebook Messenger, which aims to inspire young people in their daily life by offering them a subject on which they can give their opinion.",
        "jamText5": "",
        "jamText6": "",
        "jamText7": "",
        "jamText8": "Design a client management system with ruby on rails in Jam Trends, Jam Trends shares surveys and studies on 15-25, whenever we needed to add a new subscriber to Jam Trends website, we needed a developer because the customer management system only had a backend, so I built an interface using that backend.",
        "jamText9": "Built an API with rails and Express and design an interface in Vue Js for referral messages that help us find a specific conversation on chatbot's Facebook Messenger.",
        "jamText10": "Addition of qualification columns for the panel and the report which helps our colleague to create a report on a specific gender, age or city.",
        "jamText11": "Improvement of tagging system to answer in open question",
        "jamText12": "Improvement of Report Chart",
        "jamText13": "Design static filter by tag to answer open-ended questions",
        "jamText14": "Optimisation of the creation of panels and reports",
        "jamText15": "Isolation of Trend reports from bot databases",
        "jamText16": "Addition of the estimate of the INSEE(The national institute of statistics and economic studies) population of 2020 for creation of a panels and a reports.",
        "jamText17": "improvement the poll list and in messenger_bot gem",
        "jamText18": "Design and development Moderation interface for",
        "scarabeeText1": "Volunteer interpreter at the",
        "scarabeeText2": "association.",
        "scarabeeText3": "Helped new refugees in difficult situation and those who do not understand French. e.g. getting appointments with the doctor or administrative office or getting a lawyer. (Part-time)",
        "alvkungenText1": "Tourist guide in",
        "alvkungenText2": "Tourist boat: reception of tourist groups, guide and piloting of the boat. (Part-time)",
        "honeySellerText1": "Seller in honey store at Al Mashrik.",
        "honeySellerText2": "Seller with an artisanal honey producer (international distribution) Sale to traders and individuals.",
        "konexioText": "Preparation for Delf B1 (French language studies diploma) and Web development at Konexio Digistart.",
        "balderText": "Learned Swedish and web development at balder high school",
        "milatSchoolText": "High school diploma from Milat school / The Abasyn Web Development Institute",
        "drZareefText": "Secondary school from Dr zareef memorial school"
      },
      "options": {
        "seeMore": "See more",
        "seeLess": "See Less",
        "abouRecentJob": "About my recent job",
        "myWork": "My Work",
        "myCv": "My CV",
        "MyWorkInMultiBot": "My work in multi-bot data recovery system"
      }
    },
    "skills":  {
      "heading": {
        "programmingSkill": "Programming Skill",
        "myProjects": "My Projects",
        "interests": "Interests"
      },
      "interests": {
        "travel": "Travel",
        "cycle": "Cycle",
        "music": "Music",
        "game": "Game",
        "chess": "Chess",
        "snowboard": "Snowboard"
      },
      "myProjects": {
        "and": "and"
      },
      "programmingSkill": {
        "programmingLanguages": "Programming Languages",
        "databaseServer": "Database",
        "webTechnologies": "Web Technologies",
        "frameworks": "Frameworks",
        "otherDevTool": "Other Dev Tool",
        "famousLibraries": "Famous libraries"
      }
    },
    "contact": {
      "heading": {
        "follow": "Follow"
      }
    }
  }
}
